import React from "react";
import { graphql } from "gatsby";
import "./frontpage-template.scss";
import ImageFlip from "components/imageFlip/imageFlip";
import isMobile from "ismobilejs";
import Marquee from "components/marquee/marquee";

export const query = graphql`
  query ($id: String) {
    allWpLogo {
      nodes {
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      id
      title
      content
      imageFlip {
        imageFlip {
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  }
`;

interface pageTemplateProps {
  data: any;
  pageContext: any;
}

const PageTemplate: React.FC<pageTemplateProps> = (props) => {
  const logos = props.data.allWpLogo.nodes;
  const logo = logos[Math.floor(Math.random() * logos.length)];

  const images = props.data.wpPage.imageFlip.imageFlip;

  return (
    <div className="frontpage">
      <div className="frontpage__inner">
        {!!logo && <img src={logo.featuredImage.node.mediaItemUrl} />}
      </div>
      {!!images && <ImageFlip images={images} />}
    </div>
  );
};

export default PageTemplate;

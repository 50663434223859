import { TIME_DESTROY } from "const/const";
import React, { useEffect, useRef } from "react";

import "./imageFlipImage.scss";

export interface imageFlipImageProps {
  index: number;
  url: string;
  x: number;
  y: number;
  size: { height: number; width: number };
  onDestroy?: () => void;
}

const ImageFlipImage: React.FC<imageFlipImageProps> = (props) => {
  const timeout = useRef<any>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    !!props.onDestroy &&
      (timeout.current = setTimeout(props.onDestroy, TIME_DESTROY));
    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    if (!!mainRef.current) {
      mainRef.current.style.setProperty('--rot', `${Math.random()*30 - 15}deg`);
      mainRef.current.style.setProperty('--x', `${Math.random()*40 - 20}%`);
      mainRef.current.style.setProperty('--y', `${Math.random()*40 - 20}%`);
    }
  }, []);

  return (
    <div
      className="imageFlipImage"
      style={{ left: `${props.x}px`, top: `${props.y}px`, width: props.size.width/2, height: props.size.height/2 }}
      ref={mainRef}
    >
      <img src={props.url} className="imageFlipImage__image" />
    </div>
  );
};

export default ImageFlipImage;
